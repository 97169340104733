import http from "../http-business";

export const getSingleCertificate = async (id) => {
  const data = await http
    .get(`/certificate/certificateForClient/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      return err.response;
    });

  return data;
};

export const generateCertificate = async (id) => {
  const data = await http
    .post(`/certificate/generateBusinessClientCertificate/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      return err.response;
    });

  return data;
};

export const getPublicCertificate = async (id) => {
  const data = await http
    .get(`/certificate/viewCertificate/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
      return err.response;
    });

  return data;
};
